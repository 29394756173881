export default {
  methods: {
    getQuizPageHeight(height, RoutePage, isTest) {
      if (!isTest && RoutePage === "QuizFinal") {
        return "height:100vh;height:100dvh;";
      } else if (!isTest && RoutePage === "QuizPreview") {
        return `height:${height}px;max-height:80dvh;`;
      } else {
        return `height:${height}px;`;
      }
    },
    getEditorPersonalisedValueStyle(inputText) {
      // const placeholderRegex = /%\w+%/g;
      const placeholderRegex = /%[\w-]+%/g;
      const matches = inputText.match(placeholderRegex);

      if (!matches) {
        return inputText;
      }

      let formatted = inputText;

      matches.forEach((match) => {
        // const placeholder = match.substring(1, match.length - 1); // Remove % signs
        const formattedPlaceholder = `<span style="border: 1px dashed #4d1b7e;">${match}</span>`;
        formatted = formatted.replace(match, formattedPlaceholder);
      });

      return formatted;
    },
    getContainerFlexGrowValue(type) {
      let getCustomNavFlexGrowValue =
        this.$store.getters.getCustomNavFlexGrowValue;

      return type == "mainContainer"
        ? `overflow:scroll;background:#ebecf0;flex:${
            1 - getCustomNavFlexGrowValue
          };`
        : `flex:${getCustomNavFlexGrowValue};`;
    },

    // Global Methods functions start
    checkStaticButtonCondition(type, staticButton) {
      if (type == "button") {
        if (staticButton) return false;
        return true;
      }
      return true;
    },

    // Global Methods functions End
    // CSS Functions
    getFontStyle(property, isDesktopView) {
      return `; font-weight: ${property.fontWeight}; font-style: ${
        property.fontStyle
      }; text-decoration: ${property.textDecoration}; font-size: ${
        isDesktopView
          ? property.fontSize
          : property.mobileFontSize
          ? property.mobileFontSize
          : property.fontSize
      }px; font-family: ${property.fontFamily};`;
    },
    getDivAlignmentStyle(property) {
      let alignment;
      if (property == "left") {
        alignment = "start";
      } else if (property == "center") {
        alignment = "center";
      } else {
        alignment = "end";
      }

      return `align-items:${alignment};`;
    },
    getSelectedImageIconStyles(property) {
      if (
        property.selectedImageIconColor &&
        property.selectedImageIconColorSize
      ) {
        return `color:${property.selectedImageIconColor}; font-size:${property.selectedImageIconColorSize}px;`;
      } else {
        return `color:#000000; font-size:13px;`;
      }
    },
    getPaddingCss(property) {
      const textAlign = property.textAlign === "end" ? "right" : property.textAlign;
      return `; text-align: ${textAlign}; padding: ${property.paddingTop}px ${property.paddingRight}px ${property.paddingBottom}px ${property.paddingLeft}px; `;
    },

    getPageAlign(pageStyles,isDesktopView){
      if (pageStyles.useMobileImageAlign && !isDesktopView) {
        return  pageStyles.mobileImageAlign ;
       } else {
         return pageStyles.imageAlign;
       }
    },
    getScrollCSS(
      property,
      isDesktopView,
      property2,
      pageType,
      staticButtonValue
    ) {
      let height;
      if (isDesktopView) {
        height = `height:100%;max-height:100%;`;
      } else {
        if (
          this.getPageAlign(property) == "no" ||
          property2.displayMobile == false ||
          (typeof pageType !== "undefined" && pageType == "resultPage")
        ) {
          height = `height:100%;max-height:100%;`;
        } else {
          height = `height: ${property.height - 200}px;`;
        }
      }
      if (typeof staticButtonValue !== "undefined" && staticButtonValue) {
        return height;
      } else {
        return (
          "overflow-y:scroll; scroll-behavior: smooth; overflow-x:hidden;" +
          height
        );
      }
    },

    getStaticButtonLayoutStyle(
      value,
      isDesktopView,
      property,
      property2,
      progressValue
    ) {
      if (value) {
        let overflowCSS = `overflow-y:scroll; scroll-behavior: smooth; overflow-x:hidden;`;
        if (isDesktopView) {
          if (progressValue) {
            return overflowCSS + `height:85%; `;
          } else {
            return overflowCSS + `height:85%;`;
          }
        } else {
          if (property.imageAlign == "no" || property2.displayMobile == false) {
            if (progressValue) {
              return overflowCSS + `height:80%;`;
            } else {
              return overflowCSS + `height:85%;`;
            }
          } else {
            if (progressValue) {
              return overflowCSS + `height:70%;`;
            } else {
              return overflowCSS + `height:80%;`;
            }
          }
        }
      }
    },
    getUniversalImageStyle(
      isDesktopView,
      property,
      IsImageVariableWidthActive,
      ImageBlock
    ) {
      let height;
      if (isDesktopView) {
        height = `height:100%;max-height:100%;`;
      } else {
        if (
          typeof IsImageVariableWidthActive !== "undefined" &&
          typeof ImageBlock !== "undefined"
        ) {
          if (IsImageVariableWidthActive) {
            height = `height:${
              (ImageBlock?.imageVariableMobileHeight || 50) * 4
            }px;`;
          } else {
            height = `height:200px;`;
          }
        } else {
          height = `height:200px;`;
        }
      }

      let ObjectPos;
      if (property.xCord && property.xCord) {
        // ObjectPos = `object-position:${property.xCord}% ${property.yCord}%;`

        if (property.flipImage) {
          ObjectPos = `object-position:${100 - property.xCord}% ${
            100 - property.yCord
          }%;`;
        } else {
          ObjectPos = `object-position:${property.xCord}% ${property.yCord}%;`;
        }
      } else {
        ObjectPos = `object-position:50% 50%;`;
      }
      return (
        `display:block;width:100%;max-width:100%;margin:0px auto;object-fit:cover; ${ObjectPos}` +
        height
      );
    },
    getMainPaddingCss(property, isDesktopView) {
      let css;
      if (typeof isDesktopView !== "undefined") {
        if (isDesktopView) {
          css =
            " padding:" +
            property.paddingTop +
            "px " +
            property.paddingRight +
            "px " +
            property.paddingBottom +
            "px " +
            property.paddingLeft +
            "px; ";
        } else {
          css = `padding:${
            property?.mobilePaddingTop || property.paddingTop
          }px ${property?.mobilePaddingRight || property.paddingRight}px ${
            property?.mobilePaddingBottom || property.paddingBottom
          }px ${property?.mobilePaddingLeft || property.paddingLeft}px;`;
        }
      } else {
        css =
          " padding:" +
          property.paddingTop +
          "px " +
          property.paddingRight +
          "px " +
          property.paddingBottom +
          "px " +
          property.paddingLeft +
          "px; ";
      }
      return css;
    },
    getleftRightPadding(property) {
      return (
        " padding: 0px " +
        property.paddingRight +
        "px " +
        "0 px " +
        property.paddingLeft +
        "px; "
      );
    },
    getMarginCss(property, isDesktopView) {
      let css;
      if (typeof isDesktopView !== "undefined") {
        if (isDesktopView) {
          css = `margin:${property.marginTop}px ${property.marginRight}px ${property.marginBottom}px ${property.marginLeft}px;`;
        } else {
          css = `margin:${property?.mobileMarginTop || property.marginTop}px ${
            property?.mobileMarginRight || property.marginRight
          }px ${property?.mobileMarginBottom || property.marginBottom}px ${
            property?.mobileMarginLeft || property.marginLeft
          }px;`;
        }
      } else {
        css = `margin:${property.marginTop}px ${property.marginRight}px ${property.marginBottom}px ${property.marginLeft}px;`;
      }
      return css;
    },
    getSpaceBetweenRow(property) {
      return `margin:0px 0px ${property.spaceBetweenRow}px 0px;`;
    },
    getColorCss(property) {
      return (
        "color:" +
        property.color +
        "; background-color:" +
        property.backgroundColor
      );
    },
    getBorderCss(property) {
      return (
        "border:" + property.borderSize + "px solid " + property.borderColor
      );
    },
    getHeight(property) {
      return "; height:" + property.height + "px; ";
    },
    getWidth(property) {
      return "; width:" + property.width + "px; ";
    },
    checkHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      } else {
        return false;
      }
    },
    getButtonCss(property, isDesktopView) {
      let css = "";
      let backgroundCSS = "";
      let widthCSS = "";
      // width:'+property.buttonWidth+'px;
      css =
        ";max-width:100%;max-height:100%;min-height:" +
        property.buttonHeight +
        "px;display:flex;align-items:center;";
      const value = this.checkHexCode(property.backgroundColor);
      if (value) {
        backgroundCSS = `background-color:${
          property.backgroundColor
        }${Math.floor((property.backgroundOpacity / 100) * 255).toString(16)};`;
      } else {
        backgroundCSS = `background:${property.backgroundColor};`;
      }

      if (property.buttonWidth > 350) {
        widthCSS = `width:100%;`;
      } else {
        widthCSS = `min-width:${property.buttonWidth}px;`;
      }

      return (
        css +
        backgroundCSS +
        this.getBorderCss(property) +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        this.getMarginCss(property, isDesktopView) +
        `color:${property.color}${Math.floor(
          (property.textOpacity / 100) * 255
        ).toString(16)};` +
        this.getPaddingCss(property) +
        this.getFontStyle(property) +
        ";justify-content:" +
        property.textAlign +
        ";" +
        widthCSS
      );
    },
    getTimerButtonCss(property) {
      let css = "";
      let backgroundCSS = "";
      let widthCSS = "";
      // width:'+property.buttonWidth+'px;
      css =
        ";max-width:100%;max-height:100%;min-height:" +
        property.buttonHeight +
        "px;display:flex;align-items:center;";
      const value = this.checkHexCode(property.backgroundColor);
      if (value) {
        backgroundCSS = `background-color:${
          property.backgroundColor
        }${Math.floor((property.backgroundOpacity / 100) * 255).toString(16)};`;
      } else {
        backgroundCSS = `background:${property.backgroundColor};`;
      }

      if (property.buttonWidth > 350) {
        widthCSS = `width:100%;`;
      } else {
        widthCSS = `min-width:${property.buttonWidth}px;`;
      }

      return (
        css +
        backgroundCSS +
        this.getBorderCss(property) +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        `color:${property.color}${Math.floor(
          (property.textOpacity / 100) * 255
        ).toString(16)};` +
        this.getPaddingCss(property) +
        this.getFontStyle(property) +
        ";justify-content:" +
        property.textAlign +
        ";" +
        widthCSS
      );
    },
    getColorWithOpacity(property) {
      return `color:${property?.color || "#000"};opacity:${
        property?.textOpacity || 100
      }%;`;
    },
    getTextRotate(property) {
      return `  transform: rotate(-${property.rotate || 0}deg);`;
    },
    getLineHeight(property) {
      return `line-height:${property.lineHeight || 1};`;
    },
    getTextAlign(property) {
      let direction = this.$route.query.lang=='he'?'direction:rtl;':''
      return `text-align : ${property.textAlign === "end" ? "right" : property.textAlign};${direction}`;
    },
    getHeightWidth(property) {
      let style;

      // if(property?.width && property?.height){
      //     if(property.width < 700){
      //     style = `width:${property.width}px;height:${property.height}px;max-height:100%;max-width:100%;`
      // }
      if (property?.width) {
        if (property.width < 700) {
          style = `width:${property.width}px;max-height:100%;max-width:100%;`;
        } else {
          // style = `width:100%;height:${property.height}px;max-height:100%;max-width:100%;`
          style = `width:100%;max-height:100%;max-width:100%;`;
        }
      } else {
        style = ` width:100%;`;
      }

      return style;
    },
    getImageHeightWidth(property, isDesktopView) {
      let heightWidth;

      if (isDesktopView) {
        heightWidth = `width:${property.imageWidth}px;height:${property.imageHeight}px;`;
      } else {
        heightWidth = `width:${
          property.imageMobileWidth ? property.imageMobileWidth : property.width
        }px;height:${
          property.imageMobileHeight
            ? property.imageMobileHeight
            : property.imageHeight
        }px;`;
      }
      return (
        `max-height:100%;border-radius:${property.borderRadius}px;` +
        heightWidth
      );
    },
    getImageRotation(property) {
      return `  transform: rotate(-${property.rotate || 0}deg) scaleX(${
        property.flipImage ? "-1" : "1"
      });`;
    },
    getImageOpacity(property) {
      let filter;

      const brightnessValue = property.imageOpacity / 100;

      filter = `filter: brightness(${brightnessValue});`;
      // if (property.imageOpacity >= 100) {
      //   filter = `filter: brightness(${property.imageOpacity / 100}) contrast(${
      //     2 - property.imageOpacity / 100
      //   }) ;`;
      // } else {
      //   filter = `filter: brightness(${property.imageOpacity / 100})  ;`;
      // }

      return filter;
    },
    getBorderRadius(property) {
      return `border-radius:${property.borderRadius}px;`;
    },
    getProgressContainerDirection() {
      let css = `position:relative;`;

      return css;
    },
    getProgressDirection(property) {
      let css = `position:absolute;top:0;bottom:0;`;
      let direction = null;
      if (
        typeof property.RTLdirection !== "undefined" &&
        property.RTLdirection
      ) {
        direction = `right:0;`;
      } else {
        direction = `left:0;`;
      }

      return css + direction;
    },
    getProgressBarBorderRadius(property) {
      return `border-radius:${property.borderRadius}px;`;
    },
    getProgressTextColor(property) {
      return `color:${property.textColor};font-family:${
        property?.fontFamily || "Poppins"
      };`;
    },
    getLineProgressBarBg(property) {
      // return `background-color:${property.LineBgColor};`
      return `background:${property.LineBgColor};`;
    },
    getLineProgressBarCheckColor(property) {
      // return `background-color:${property.LineBgColor};`
      return `color:${property.LineBgColor};`;
    },
    getLineProgressCheckControlBg(property) {
      // return `background-color:${property.LineControlBg};`
      return `color:${property.LineControlBg};`;
    },
    getProgressBarHeight(property) {
      return `height:${property.lineHeight}px;`;
    },
    getLineProgressBarOpacity(property) {
      return `opacity:${property?.LineBgOpacity || 100}%;`;
    },
    getLineProgressControlBg(property) {
      // return `background-color:${property.LineControlBg};`
      return `background:${property.LineControlBg};`;
    },
    getLineProgressControlOpacity(property) {
      return `opacity:${property?.LineControlOpacity || 100}%;`;
    },
    getDotProgressControlOpacity(property) {
      return `opacity:${property?.DotsControlOpacity || 100}%;`;
    },
    getDotProgressBarOpacity(property) {
      return `opacity:${property?.DotsBgOpacity || 100}%;`;
    },
    getDotsProgressBarBg(property) {
      // return `background-color:${property.DotsBgColor};`
      return `background:${property.DotsBgColor};`;
    },
    getDotsProgressControlBg(property) {
      // return `background-color:${property.DotsControlBg};`
      return `background:${property.DotsControlBg};`;
    },
    getDotsHeightWidth(property) {
      return `width:${property.lineHeight}px;height:${property.lineHeight}px;`;
    },
    getCheckFontSize(property) {
      return `font-size:${property.lineHeight}px;`;
    },
    // getbackgroundImage(img){
    //     return `background-image: url(${img}); background-size:cover`
    //   },

    getCommonButtonCSS(property, isDesktopView) {
      return this.getMarginCss(property, isDesktopView);
      // return  `margin:${property.marginTop}px ${property.marginRight}px ${property.marginBottom}px ${property.marginLeft}px;`
    },
    getBackNextButtonCss(property, isDesktopView) {
      let css = "";
      let backgroundCSS = "";
      
      const value = this.checkHexCode(property.backgroundColor);
      const backgroundOpacityHex = Math.floor((property.backgroundOpacity / 100) * 255).toString(16);
      const textOpacityHex = Math.floor((property.textOpacity / 100) * 255).toString(16);
    
      backgroundCSS = value 
        ? `background-color:${property.backgroundColor}${backgroundOpacityHex};color:${property.color}${textOpacityHex};`
        : `background:${property.backgroundColor};color:${property.color}${textOpacityHex};`;
    
      const minWidth = isDesktopView
        ? `${property.buttonWidth}px`
        : property.mobileButtonWidth && property.mobileButtonWidth > 0
        ? `${property.mobileButtonWidth}px`
        : `${property.buttonWidth}px`;
    
      const minHeight = isDesktopView
        ? `${property.buttonHeight}px`
        : property.mobileButtonHeight && property.mobileButtonHeight > 0
        ? `${property.mobileButtonHeight}px`
        : `${property.buttonHeight}px`;
    
      css = `;max-width:100%;max-height:100%; min-width:${minWidth};min-height:${minHeight};`;
    
      return (
        css +
        backgroundCSS +
        this.getBorderCss(property) +
        `; border-radius:${property.borderRadius}px; ` +
        this.getFontStyle(property) +
        this.getBackNextPaddingCss(property)
      );
    }
    ,
    getBackNextPaddingCss(property) {
      return (
        ";display:flex;align-items:center; justify-content:" +
        property.textAlign +
        ";"
      );
      // padding:' + property.paddingTop + 'px ' + property.paddingRight + 'px ' + property.paddingBottom + 'px ' + property.paddingLeft + 'px;
    },
    getProgressQuestionTextAlign(property) {
      return "text-align:" + property.questionAlign + ";";
    },
    getSelectedTabStyles(property, property2) {
      let textAlign = `text-align:${property2?.tabBtnTextAlign || "center"};`;
      return (
        "color:" +
        property.textColor +
        ";padding:10px 14px; background:" +
        property.backgroundColor +
        ";border-radius:" +
        property.borderRadius +
        "px;" +
        "opacity:" +
        property.bgOpacity +
        "%; font-weight:" +
        property2.fontWeight +
        "; font-style:" +
        property2.fontStyle +
        ";text-decoration:" +
        property2.textDecoration +
        ";font-size:" +
        property2.fontSize +
        "px;font-family:" +
        property2.fontFamily +
        ";" +
        textAlign
      );
    },
    getDefaultTabStyles(property, property2) {
      let textAlign = `text-align:${property2?.tabBtnTextAlign || "center"};`;
      return (
        "color:" +
        property2.color +
        "; padding:10px 14px; background:" +
        property.backgroundColor +
        ";border-radius:" +
        property.borderRadius +
        "px;" +
        "opacity:" +
        property.bgOpacity +
        "%; font-weight:" +
        property2.fontWeight +
        "; font-style:" +
        property2.fontStyle +
        ";text-decoration:" +
        property2.textDecoration +
        ";font-size:" +
        property2.fontSize +
        "px;font-family:" +
        property2.fontFamily +
        "; " +
        textAlign
      );
      // return 'color:' + property2.color + '; background-color:' +property.backgroundColor+ ';border-radius:' +property.borderRadius+'px;'+'opacity:' +property.bgOpacity+'%; font-weight:' + property2.fontWeight + '; font-style:' + property2.fontStyle+ ';text-decoration:'+property2.textDecoration  + ';font-size:' + property2.fontSize + 'px;font-family:' + property2.fontFamily+'; '
    },

    getLinkStyle(property) {
      return (
        "color:" +
        property.strokeColor +
        ";opacity:" +
        property.strokeOpacity +
        "%;"
      );
    },
    // padding:' + property.paddingTop + 'px ' + property.paddingRight + 'px ' + property.paddingBottom + 'px ' + '10px;
    getProductRankCss(property) {
      return (
        "; text-align: center;padding:10px 10px 10px 10px; color:" +
        property.buttonTextColor +
        "; background:" +
        property.buttonBgColor +
        ";min-width:80px;width:fit-content; max-width:300px; border:none; border-radius:50px"
      );
    },
    getProductButtonCss(property) {
      return `
      text-align: center; 
      padding: ${property.paddingTop}px ${property.paddingRight}px ${property.paddingBottom}px ${property.paddingLeft}px; 
      color: ${property.buttonTextColor}; 
      background: ${property.buttonBgColor}; 
      width: 100%; 
      border: ${property.buttonBorderSize}px solid ${property.buttonBorderColor}; 
      border-radius: ${property.buttonBorderRadius}px;
      ${property.buttonDesktopFontSize ? `font-size: ${property.buttonDesktopFontSize}px;` : ''}
      ${property.buttonFontFamily ? `font-family: ${property.buttonFontFamily};` : ''}
    `;
    },
    freeTextInputStyles(property) {
      let css =
        this.getFontStyle(property) +
        "text-align:" +
        property.textAlign +
        "; opacity:" +
        property.textOpacity +
        "%; --inputTextColor:" +
        property.color +
        "; " +
        " --inputPlaceholderColor:" +
        property.strokeColor +
        ";" +
        " padding: 10px 16px 10px 16px;";

      let height = "";
      if (Object.prototype.hasOwnProperty.call(property, "textAreaHeight")) {
        height = `height:${property.textAreaHeight}px;`;
      }
      return height != "" ? css + height : css;
    },
    inputRangeStyles(property) {
      return (
        "--trackColor:" +
        property.selectedColor +
        "; --controlColor:" +
        property.controlColor +
        "; --trackOpacity:" +
        property.selectedOpacity +
        "%; --controlOpacity:" +
        property.controlOpacity +
        "%; --rangeHeight:" +
        property.SliderSize +
        "px; --itemHeight:" +
        property.ItemSize +
        "px; --itemAlign:" +
        property.ItemAlign +
        ";"
      );
    },
    InputRangeValues(property) {
      return (
        "--textColor:" +
        property.color +
        "; --textOpacity:" +
        property.textOpacity +
        "%; --backgroundColor:" +
        property.selectedColor +
        "1c; --fontWeight:" +
        property.fontWeight +
        "; --fontStyle:" +
        property.fontStyle +
        ";--textDecoration:" +
        property.textDecoration +
        "; --fontSize:" +
        property.fontSize +
        ";--fontFamily:" +
        property.fontFamily +
        "; --mainBackground:" +
        property.selectedColor +
        "4d;"
      );
    },
    getBackNextButtonPosition(property, pageType) {
      let style;
      if (
        pageType == "multipleAnswer" &&
        property.content.backButton.hideBackButton
      ) {
        style = `justify-content: ${property.style.buttonPosition}`;
      } else if (
        pageType !== "multipleAnswer" &&
        (property.content.nextButton.hideNextButton ||
          property.content.backButton.hideBackButton)
      ) {
        style = `justify-content: ${property.style.buttonPosition}`;
      } else {
        style = `justify-content:space-between`;
      }
      return style;
    },
    getBgDivStyles() {
      return `position:absolute;top: 0;
        bottom: 0;
        left: 0;
        right: 0;width: 100%;
          height: 100%; z-index: -100;`;
    },

    getbackgroundImage(IsImageAdded, property, imageAlign, isDesktopView) {
      if (IsImageAdded && property.src !== "" && imageAlign == "no") {
        let filter;
        const brightnessValue = property.style.imageOpacity / 100;

        filter = `filter: brightness(${brightnessValue});`;

        let backgroundPos;
        if (property.style.xCord && property.style.xCord) {
          backgroundPos = `background-position:${property.style.xCord}% ${property.style.yCord}%;`;
        } else {
          backgroundPos = `background-position:50% 50%;`;
        }

        let flipImage = `transform : scaleX(${
          property.style.flipImage ? "-1" : "1"
        });`;

        // return `background-image: url(${property.src}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos}  ${filter}`
        return `background-image: url(${this.MainImgSrc(
          this.getImageSrc(property, isDesktopView)
        )}); background-repeat: no-repeat; background-size:  cover; ${backgroundPos}  ${filter} ${flipImage}`;
      }
    },

    detailProductContainerStyle(isDesktopView, showImage) {
      if (isDesktopView) {
        // return `height:400px;`
        return typeof showImage != "undefined" && showImage
          ? `height:400px;`
          : `height:100%;`;
      } else {
        return ``;
      }
    },
    getDescriptionStyles(isDesktopView) {
      if (isDesktopView) {
        return `height:200px;overflow-y:scroll;`;
      } else {
        return `height:200px;overflow-y:scroll;`;
      }
    },
    getProductCarouselImageClass(isDesktopView) {
      if (isDesktopView) {
        return "col-6";
      } else {
        return `col-12 `;
      }
    },
    getProductCarouselImageStyle(isDesktopView) {
      if (isDesktopView) {
        return "height:100%;";
      } else {
        return `height:300px;`;
      }
    },
    getMoreOptionStyles(property) {
      return `color:${property?.detailListTextColor || "#000"};opacity:${
        property?.detailListTextOpacity || 100
      }%; 
        font-size:${property?.detailListFontSize}px;
         font-weight:${property?.detailListFontWeight || "bold"}
        
        ; font-style:${property?.detailListFontStyle || "normal"}
        
        ;text-decoration:${property?.detailListTextDecoration || "unset"}
        ;
        font-family:${property?.detailListFontFamily || "Poppins"}
        
        ; text-align:${property?.detailListTextAlign || "center"}
        
        ;`;
    },

    getDetailProductImageFit(property) {
      let imageFitCSS;
      if (property.imageFit === "Fit") {
        imageFitCSS = "object-fit: contain" + ";";
      } else if (property.imageFit === "Fill") {
        imageFitCSS = "object-fit: fill" + ";";
      }
      return imageFitCSS;
    },
    getImageborderRadius(property) {
      return `border-top-left-radius:${property.borderRadius}px;border-bottom-left-radius:${property.borderRadius}px;`;
    },
    getDetailProductImageRatio(property) {
      let imageRatioCSS;
      if (property.imageRatio === "square") {
        imageRatioCSS = "";
      } else if (property.imageRatio === "potrait") {
        imageRatioCSS = `position:absolute;height:100%; width:66%; margin:auto; left:0; right:0;`;
      } else {
        imageRatioCSS = `position:absolute; width:100%; height:66%; margin:auto; top:0;left:0; bottom:0;`;
      }

      return " width: 100% " + ";" + "height: 100%" + ";" + imageRatioCSS;
    },
    getStaticButtonDivCss(block) {
      if (block.customizeStatus) return "border:2px solid #4065d5 !important;";
      else return "border:2px solid transparent";
    },

    //   Date Input Styles

    getDateTextColor(property) {
      return `color:${property.DateTextColor}; opacity:${property.DateTextOpacity}%;`;
    },
    getDateTextFontStyle(property) {
      return ` font-weight: ${property.DateFontWeight}; font-style: ${property.DateFontStyle};text-decoration:${property.DateTextDecoration};font-size:${property.DateFontSize}px;font-family:${property.DateFontFamily};`;
    },
    customInputStyles(property) {
      return ` --inputPlaceholderColor:${property.strokeColor} ; --inputColor:${
        property.color
      } ; text-align:${property.textInputAlign} ; opacity: ${
        property?.textInputOpacity || 100
      }%;border:none;padding:10px 16px;`;
    },
    QuestionPageStyle(property) {
      return " --dropdownHoverColor:" + property.color + "; ";
    },
    height100(property) {
      if (property) return `height:100%;cursor:pointer;`;
      return null;
    },
    maxHeight() {
      return `height:100%;max-height:100%;`;
    },
    checkMaxHeight(progressValue, isDesktopView) {
      if (progressValue) {
        if (isDesktopView) {
          return true;
          // return false
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    ifProgressTrueHeight() {
      return "height:89%;max-height:84%;";
    },

    getImageSrc(property, isDesktopView) {
      return isDesktopView || !property.useMobileImage
        ? property.src
        : property.useMobileImageSrc;
    },

    MainImgSrc(image) {
      if (image == "") return "";
      if (image.indexOf("http://") === 0 || image.indexOf("https://") === 0) {
        return image;
      } else {
        let dbImage;
        if (image.startsWith("/")) {
          dbImage = image.substring(1);
        } else {
          dbImage = image;
        }
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        // return baseURL + image
        return baseURL + dbImage;
      }
    },

    selectedCheckboxColor(property) {
      return `--selectedCheckboxColor : ${
        property?.selectedCheckboxColor || "#000000"
      };--checkboxHeight:${property.fontSize};--checkboxWidth:${
        property.fontSize
      };`;
    },
    inputHeightWidth(property) {
      return `height:${property.fontSize}px;width:${property.fontSize}px;`;
    },

    limitProductDescription(limitDescription, readMore, isDesktopView) {
      let height = "";
      if (typeof limitDescription != "undefined" && limitDescription) {
        if (readMore) {
          if (typeof isDesktopView != "undefined" && isDesktopView) {
            height = this.getDescriptionStyles(isDesktopView);
          } else {
            height = `height:100%;`;
          }
        } else {
          height = `max-height:200px;overflow-x:hidden;overflow-y:auto`;
        }
      } else {
        if (typeof isDesktopView != "undefined" && isDesktopView) {
          height = this.getDescriptionStyles(isDesktopView);
        } else {
          height = `height:100%;`;
        }
      }

      return height;
    },
    limitDeatilProductDescription(
      limitDescription,
      readMore,
      isDesktopView,
      detailLink
    ) {
      let height = "";
      if (typeof limitDescription != "undefined" && limitDescription) {
        if (readMore) {
          if (typeof isDesktopView != "undefined" && isDesktopView) {
            height = detailLink
              ? `max-height:200px;overflow-y:scroll;`
              : `height:250px;overflow-y:scroll;`;
          } else {
            height = `height:100%;`;
          }
        } else {
          height = detailLink
            ? `height:200px;overflow-y:scroll;`
            : `height:250px;overflow-y:scroll;`;
        }
      } else {
        if (typeof isDesktopView != "undefined" && isDesktopView) {
          height = `max-height:200px;overflow-y:scroll;`;
        } else {
          height = `height:100%;`;
        }
      }

      return height;
    },

    ListProductMaxWidth(property) {
      if (property) {
        let value = 12 / property;

        if (value == 12) {
          return null;
          // return `max-width:300px;`
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    leadLabelStyle(property) {
      return `color:${property.color};text-align:${property.textInputAlign};`;
    },

    ListImageAspectRatio(property) {
      let imageRatioCSS;
      if (property.imageRatio === "square") {
        imageRatioCSS = `
        padding-top: 100%;`;
      } else if (property.imageRatio === "potrait") {
        //  imageRatioCSS = `position:absolute;height:100%; width:66%; margin:auto; left:0; right:0;`
        imageRatioCSS = `padding-top:125.25%;`;
      } else {
        //   imageRatioCSS =  `position:absolute; width:100%; height:66%; margin:auto; top:0; bottom:0;`
        imageRatioCSS = `padding-top:66.66%`;
      }

      return (
        `position: relative;backgorund:red;
    width: 100%;` + imageRatioCSS
      );
    },
    authButtonStyles(property) {
      let css = "";
      let backgroundCSS = "";
      css = `min-width:80px;max-width:100%;max-height:100%; width:${property.sendBtnWidth}px;height:${property.sendBtnHeight}px;display:flex;justify-content:center;align-items:center;color:${property.sendBtnColor}; font-weight: ${property.sendBtnFontWeight} ; font-style: ${property.sendBtnFontStyle};text-decoration:${property.sendBtnTextDecoration}  ;font-size: ${property.sendBtnFontSize}px;font-family:${property.sendBtnFontFamily} ; border-radius:${property.sendBtnBorderRadius}px;`;
      const value = this.checkHexCode(property.sendBtnBGColor);
      if (value) {
        backgroundCSS = `background-color:${
          property.sendBtnBGColor
        }${Math.floor((100 / 100) * 255).toString(16)};`;
      } else {
        backgroundCSS = `background:${property.sendBtnBGColor};`;
      }

      return css + backgroundCSS;
    },
    ElthClientHeight(value) {
      if (value) {
        return `height:100% !important;`;
      } else {
        return null;
      }
    },
    getPriceCss(property) {
      return (
        this.getFontStyle(property) +
        `margin:${property.marginTop}px ${property.marginRight}px ${property.marginBottom}px ${property.marginLeft}px;` +
        this.getPaddingCss(property) +
        `color:${property.color};`
      );
    },
    getSelectedOptionsArr() {
      return [...this.$store.getters.getSelectedOptions.questionAnswer];
    },
    getTextValue(text) {
      // const userCustomLeadInputFields = [
      //   ...this.$store.getters.getUserCustomLeadInputFields,
      // ];
      const { custom_inputs } = this.$store.getters.getSavedLeadID;
      const userCustomLeadInputFields =
        !custom_inputs || Object.keys(custom_inputs).length === 0
          ? []
          : Object.keys(custom_inputs).map((key) => ({
              field_name: key,
            }));

      let allQuestions = [...this.$store.getters.getAllPreviewQuestions];
      if (this.$route.query.result_key !== "") {
        const options = [
          ...this.$store.getters.getSelectedOptions.questionAnswer,
        ];
        let modifiedOptions = options.map((option, index) => {
          let personalization = `QA-${index + 1}-${option.questionId}`;

          return { personalization: personalization };
        });

        allQuestions = modifiedOptions;
      }

      const customInputSet = new Set(
        userCustomLeadInputFields.map((x) => x.field_name)
      );

      const placeholders = new Set([
        "email",
        "name",
        "score",
        "date",
        "phone",
        "organisation",
        "website",
        "city",
        "state",
        "country",
        "address_1",
        "address_2",
        "zip_code",
      ]);
      const QAInputs = new Set(allQuestions.map((x) => x.personalization));

      const isInputPresent = (placeholder) =>
        placeholders.has(placeholder.toLowerCase());
      const getIdFromString = (str) => str.match(/[^-]+$/)[0];

      const regex = /%([^%]+)%/g;
      let matches = text.match(regex) || [];
      matches = matches.map((match) => match.slice(1, -1));

      const placeholderValues = {};

      for (const key of placeholders) {
        if (isInputPresent(key)) {
          const value = this[key];
          placeholderValues[key] = value;
        }
      }

      if (customInputSet.size > 0) {
        for (const key of matches) {
          if (customInputSet.has(key) && custom_inputs[key] !== undefined) {
            placeholderValues[key] = custom_inputs[key];
          }
        }
      }

      for (const key of matches) {
        if (QAInputs.size > 0 && QAInputs.has(key)) {
          const quesId = getIdFromString(key);
          const selectedOptionsIndex = this.getSelectedOptionsArr().findIndex(
            (obj) => obj.questionId == quesId
          );

          if (selectedOptionsIndex > -1) {
            const { selectedOption } =
              this.getSelectedOptionsArr()[selectedOptionsIndex];
            const value = this.getSelectedOptionsOutput(selectedOption);
            placeholderValues[key] = value;
          }
        }
        if (key == "result_link") {
          if (this.getResultLinkValue()) {
            placeholderValues[key] = this.getResultLinkValue();
          }
        }
      }

      for (const key in placeholderValues) {
        const value = placeholderValues[key];
        const regex = new RegExp(`%${key}%`, "gi");
        text = text.replace(regex, value);
      }

      return text;
    },

    getResultLinkValue() {
      const elthAllowedId = [
        process.env.VUE_APP_ITALIAN_QUIZID,
        process.env.VUE_APP_ELTH_QUIZID,
        process.env.VUE_APP_ELTH_V3_QUIZID,
      ];

      const { quiz_id, result_key, email } = this.$store.getters.getSavedLeadID;
      const { id } = this.$route.params;

      if (quiz_id && elthAllowedId.includes(quiz_id)) {
        const baseRoute = `${window.location.origin}/preview/${id}`;
        const queryParams = result_key ? `?result_key=${result_key}` : "";
        const emailParam = email ? `&email=${email}` : "";

        return result_key && id
          ? `${baseRoute}${queryParams}${emailParam}`
          : null;
      }

      return result_key && id
        ? `${window.location.origin}/preview/${id}?result_key=${result_key}`
        : null;
    },

    getSelectedOptionsOutput(data) {
      if (Array.isArray(data)) {
        const values = data.map((option) => option.value);
        return values.join(", ");
      } else if (typeof data === "object" && data !== null) {
        return data.value;
      }
      // Handle other cases if needed
      return data;
    },

    getToolTipPosition(property) {
      let posCss = "";
      let pseudoStyles;

      if (property.tooltipPosition == "top") {
        // posCss += `top:-${property.tooltipHeight}px;`
        posCss += `top:${
          parseInt(property.marginTop) - (parseInt(property.tooltipHeight) + 10)
        }px;`;

        if (property.position == "left") {
          posCss += `left:0;transform:translateX(0%);`;
        }
        if (property.position == "center") {
          posCss += `left:50%;transform:translateX(-50%);`;
        }
        if (property.position == "right") {
          posCss += `right:0;transform:translateX(0%);`;
        }
      }
      if (property.tooltipPosition == "bottom") {
        // posCss += `bottom:-${property.tooltipHeight}px;`
        posCss += `bottom:${
          parseInt(property.marginBottom) -
          (parseInt(property.tooltipHeight) + 10)
        }px;`;

        if (property.position == "left") {
          posCss += `left:0;transform:translateX(0%);`;
        }
        if (property.position == "center") {
          posCss += `left:50%;transform:translateX(-50%);`;
        }
        if (property.position == "right") {
          posCss += `right:0;transform:translateX(0%);`;
        }
      }

      // PseudoStyles Start
      if (property.position == "left") {
        pseudoStyles = `--pseudoLeftPos:4%;`;
      }
      if (property.position == "center") {
        pseudoStyles = `--pseudoLeftPos:50%;`;
      }
      if (property.position == "right") {
        pseudoStyles = `--pseudoLeftPos:97%;`;
      }
      // PseudoStyles End

      // let posCss = property.tooltipPosition == 'top' ? `top:-${property.tooltipHeight}px;left:50%;transform:translateX(-50%);` : `bottom:-${property.tooltipHeight}px;left:50%;transform:translateX(-50%);`

      return (
        posCss +
        `z-index:100000000 !important;--tooltipArrowColor:${property.tooltipBg};--tooltipMarginCal:${property.tooltipHeight}px;` +
        pseudoStyles
      );
    },
    getToolTipCompStyles(property, isDesktopView) {
      let css;
      let backgroundColorCss;
      let paddingCss;

      css = `max-width:100%;width:${property.tooltipWidth}px;height:${property.tooltipHeight}px; max-height:${property.tooltipHeight}px; overflow:hidden scroll;border-radius:${property.tooltipTextBorderRadius}px;`;
      const value = this.checkHexCode(property.tooltipBg);
      if (value) {
        backgroundColorCss = `background-color:${
          property.tooltipBg
        }${Math.floor((100 / 100) * 255).toString(16)};`;
      } else {
        backgroundColorCss = `background:${property.tooltipBg};`;
      }

      if (isDesktopView) {
        paddingCss =
          " padding:" +
          property.tooltipPaddingTop +
          "px " +
          property.tooltipPaddingRight +
          "px " +
          property.tooltipPaddingBottom +
          "px " +
          property.tooltipPaddingLeft +
          "px; ";
      } else {
        paddingCss = `padding:${
          property?.tooltipMobilePaddingTop || property.tooltipPaddingTop
        }px ${
          property?.tooltipMobilePaddingRight || property.tooltipPaddingRight
        }px ${
          property?.tooltipMobilePaddingBottom || property.tooltipPaddingBottom
        }px ${
          property?.tooltipMobilePaddingLeft || property.tooltipPaddingLeft
        }px;`;
      }

      return css + backgroundColorCss + paddingCss;
    },
    getToolTipTextStyle(property) {
      return `color:${property.tooltipTextColor}; font-weight: ${property.tooltipFontWeight} ; font-style: ${property.tooltipFontStyle};text-decoration:${property.tooltipTextDecoration}  ;font-size: ${property.tooltipTextFontSize}px;font-family:${property.tooltipFontFamily} ;text-align:${property.tooltipTextAlign};`;
    },
    getCircularBtn(property) {
      return property ? "border-radius:50%;" : null;
    },

    getBulletStyles(property) {
      let textAlignCss;
      if (property?.bulletDescriptiontextAlign) {
        if (property?.bulletDescriptiontextAlign == "left")
          textAlignCss = `justify-content:start;`;
        if (property?.bulletDescriptiontextAlign == "center")
          textAlignCss = `justify-content:center;`;
        if (property?.bulletDescriptiontextAlign == "end")
          textAlignCss = `justify-content:end;`;
      } else {
        textAlignCss = `justify-content:start;`;
      }
      return (
        `color:${property?.bulletDescriptiontitleColor || "#000"};opacity:${
          property?.bulletDescriptiontitleTextOpacity || 100
        }%;font-family:${property?.bulletDescriptionfontFamily || "Poppins"};
        font-weight:${
          property?.bulletDescriptionfontWeight || "normal"
        };  font-style:${
          property?.bulletDescriptionfontStyle || "normal"
        }; text-decoration:${
          property?.bulletDescriptiontextDecoration || "unset"
        };;font-size:${
          property?.bulletDescriptionfontSize || "15"
        }px;--BulletDescriptionIconColor:${
          property?.bulletDescriptiontitleColor || "#000"
        };` + textAlignCss
      );
    },
    getSocialLinkBtnStyles(property, isDesktopView) {
      let sizeCss;

      sizeCss = isDesktopView
        ? `width:${property.IconSize * 10}px;height:${
            property.IconSize * 10
          }px;`
        : `width:${property.IconMobileSize * 20}px;height:${
            property.IconMobileSize * 20
          }px;`;

      return (
        `cursor:pointer; --socialIconColor:${property.IconColor};` + sizeCss
      );
    },
    verifyOtpTextStyles(property) {
      return `font-weight:${property.fontWeight};font-style:${
        property.fontStyle
      };text-decoration:${property.textDecoration};font-size:${
        property.fontSize
      }px;font-family:${property.fontFamily};text-align:${
        property.textAlign
      };color:${property.color}${Math.floor(
        (property.textOpacity / 100) * 255
      ).toString(16)};`;
    },

    getDropBoxStyle(property) {
      return `outline: 2px dashed ${property.color};
        outline-offset: -10px;
        color:${property.color};
        min-height: ${property.InputHeight}px;
        position: relative;
        cursor: pointer;
        display:flex;
        flex-direction: column;
        justify-content: center;
       
        --hoverColor: ${property.strokeColor};`;
    },
    getDropBoxTextStyle(property) {
      return `color:${property?.color || "#000"};opacity:${
        property?.textOpacity || 100
      }%;font-family:${property?.fontFamily || "Poppins"};
        font-weight:${property?.fontWeight || "normal"};  font-style:${
        property?.fontStyle || "normal"
      }; text-decoration:${property?.textDecoration || "unset"};font-size:${
        property?.fontSize || "15"
      }px;text-align:${property.textAlign};padding:0 15px;line-height:${
        property.lineHeight || 1
      };`;
    },
    calculateImageWidth(property, isDesktopView, IsImageVariableWidthActive) {
      if (isDesktopView && IsImageVariableWidthActive) {
        return `flex: 0 0 ${property.imageVariableWidth}%;
            max-width: ${property.imageVariableWidth}%;`;
      } else {
        return null;
      }
    },
    calculateContentWidth(
      property,
      isDesktopView,
      IsImageVariableWidthActive,
      pageStyles,
      pageType
    ) {
      if (typeof pageType !== undefined && pageType == "resultPage") return;

      if (isDesktopView && IsImageVariableWidthActive) {
        return `flex: 0 0 ${100 - property.imageVariableWidth}%;
            max-width: ${100 - property.imageVariableWidth}%;`;
      } else {
        return IsImageVariableWidthActive &&
          typeof pageStyles !== "undefined" &&
          property.style.displayMobile
          ? ` height : ${
              pageStyles.height -
              (property?.imageVariableMobileHeight || 50) * 4
            }px;`
          : null;
      }
    },
  },
  timerLabelStyle(label) {
    return {
      backgroundColor: `background-color:${
        label.style.backgroundColor
      }${Math.floor((label.style.backgroundOpacity / 100) * 255).toString(
        16
      )};color:${label.style.color}${Math.floor(
        (label.style.textOpacity / 100) * 255
      ).toString(16)};`,
      color: label.style.color,
      fontWeight: label.style.fontWeight,
      fontFamily: label.style.fontFamily,
      textAlign: label.style.textAlign,
      // fontWeight:label.style.fontWeight,
    };
  },
};
